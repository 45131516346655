import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Navigation from "../components/navigation/navigation"
import Header from "../components/sections/Header"
import Features from "../components/sections/Features"
import Footer from "../components/sections/footer/Footer"
import GetStarted from "../components/sections/GetStarted"
import PlatformSection from "../components/sections/PlatformSection";

const IndexPage = () => (
  <Layout>
    <SEO title="ERG" />
    <Navigation />
    <Header />
    <Features />
    <GetStarted />
    <PlatformSection />
    <Footer />
  </Layout>
)

export default IndexPage
