import * as React from "react"
import styled from "styled-components"

import Section from "../Section"
import Container from "../Container";

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FormSubtitleLink = styled.a`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
`

const PlatformSection = () => (
  <StyledSection id='platform'>
    <Container>
      <Subtitle>What is Ergo</Subtitle>
      <div>
        <p>
          Ergo is a Resilient Platform for Contractual Money. It is designed to be a platform for applications with the
          main focus to provide an efficient, secure and easy way to implement financial contracts.
        </p>
        <p>
          Ergo Platform includes various technical and economic improvements to existing blockchain solutions. Every
          coin in Ergo is protected by a program in ErgoScript, which is a powerful and protocol friendly scripting
          language based on Σ-protocols. It specifies conditions under which a coin can be used: who can spend them,
          when, under what external conditions, to whom, and so on.
        </p>
        <p>
          To be useful in the long-term, Ergo is strictly following a long‐term survivability approach — it uses
          widely-researched solutions that are not going to result in security issues in the future, while also
          preventing performance degradation over time with a new economic model.
        </p>
        <p>
          Finally, Ergo is a self‐amendable protocol, that allows it to absorb new ideas and improve itself in the
          future. The focuses on long‐term survivability and self‐amendability are what give Ergo its resiliency.
        </p>
        <p>
          Learn more about Ergo -
          <FormSubtitleLink target="_blank"
                            href="https://ergoplatform.org?utm_source=ergowallet">ergoplatform.org</FormSubtitleLink>
        </p>
      </div>
    </Container>
  </StyledSection>
)

export default PlatformSection

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`


